<template>
  <div>
    <template>
      <div class="nav-title">Account</div>
      <ul class="nav">
        <li class="nav-item">
          <router-link
            :to="{ name: 'dashboard' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="grid-outline" class="text-12 mr-4" />
            <span>Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'daily-summary' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon
              name="stats-chart-outline"
              class="text-12 mr-4"
            ></ion-icon>
            <span>Daily Summary</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'referrals-investments' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Referrals Investments</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'referral-investment-commissions' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Referrals Investment Commissions</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'conversions' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon
              name="stop-circle-outline"
              class="text-12 mr-4"
            ></ion-icon>
            <span>Conversions</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'withdrawal-request' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="list-circle-outline" class="text-12 mr-4" />
            <span>Withdrawal Request</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'payment-history' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="list-outline" class="text-12 mr-4" />
            <span>Payout History</span>
          </router-link>
        </li>
      </ul>
    </template>



    <template>
      <div class="nav-title">Settings</div>
      <ul class="nav">
        <li class="nav-item">
          <router-link
            :to="{ name: 'payment-method' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Bank Account Details</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'settings-password' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="lock-closed-outline" class="text-12 mr-4" />
            <span>Change Password</span>
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    collapse(element) {
      window.collapse(element, "toggle");
    },
  },
  computed: {
    staffAccountType() {
      return this.$store.state.session.staff_account_type;
    },
  },
};
</script>
