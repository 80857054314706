<template>
    <div class="flex flex-wrap mb-4">
      <div class="w-full px-10 mt-10 flex justify-between">
        <h2 class="font-bold text-3xl mb-10">Referral Investment Commissions</h2>
        <div class="w-3/5">
          <search-form
            v-model="searchQuery"
            placeholder="Search for user's name, status, etc."
            @submit="(event) => $refs.table.loadAjaxData()"
          />
        </div>
      </div>
  
      <div class="w-full px-10 mb-20">
        <div class="border border-solid border-blue-200">
          <datatable2
            :url="`${this.$baseurl}/affiliate/investments/commissions`"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :columns="table.columns"
            :limit="10"
            ref="table"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  export default {
    data() {
      return {
        table: this.$options.resource([], {
          columns: [
            {
              name: "investment_amount",
              th: "Investment Amount",
            },
            {
                name: "investment_tenure",
                th: "Investment Tenure"
            },            
            {
              name: "commission_amount",
              th: "Commission",
            },
            {
              name: "commission_rate",
              th: "Rate"
            },
            {
              name: "created_at",
              th: "Creation Date",
              render: (commission) =>
                moment(commission.created_at).format("MMM Do, YYYY"),
            },
            {
              name: "paid_at",
              th: "Payment Status",
              render: (commission) => {
                if (!commission.paid_at) {
                    return `<div class="badge badge-orange-soft-outline">
                                              Pending
                                          </div>`;
                }
                  return `<div class="badge badge-green-soft-outline">
                                              ${moment(commission.paid_at).format("MMM Do, YYYY")}
                                          </div>`;
                
              },
            },
          ],
        }),
      };
    },
  };
  </script>
  