<template>
  <div class="flex flex-wrap mb-4">
    <div class="w-full px-10 mt-10 flex justify-between">
      <h2 class="font-bold text-3xl mb-10">Referrals Investments</h2>
      <div class="w-3/5">
        <search-form
          v-model="searchQuery"
          placeholder="Search for user's name, status, etc."
          @submit="(event) => $refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="w-full px-10 mb-20">
      <div class="border border-solid border-blue-200">
        <datatable2
          :url="url"
          :ajax="true"
          :index="true"
          :fillable="true"
          :columns="conversions.columns"
          :limit="10"
          :query="searchQuery"
          :loading="conversions.loading"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      url: `${this.$baseurl}/affiliate/investments`,
      searchQuery: "",
      conversions: this.$options.resource([], {
        columns: [
          {
            name: "user_name",
            th: "Customer Name",
          },
          {
            name: "amount",
            th: "Amount",
          },
          {
            name: "days",
            th: "Duration",
          },
          {
            name: "created_date",
            th: "Creation Date",
            render: (conversions) =>
              moment(conversions.created_at).format("MMM Do, YYYY"),
          },
          {
            name: "commission",
            th: "Commission",
            render: (conversions) => {
              if (!conversions.reward) {
                return "---";
              }
              return this.$options.filters.currency(conversions.reward.commission || 0);
            },
          },
          {
            name: "confirmed_date",
            th: "Confirmed Date",
            render: (conversions) => {
              if (!conversions.confirmed_date) {
                return "---";
              }
              return moment(conversions.confirmed_date).format("MMM Do, YYYY h:mm:ss a");
            },
          },
          {
            name: "status",
            th: "Conversion Status",
            render: (conversions, status) => {
              switch (status) {
                case "approved":
                  return `<div class="badge badge-green-soft-outline">
                                              Approved
                                          </div>`;
                case "declined":
                  return `<div class="badge badge-red-soft-outline">
                                              Rejected
                      </div>`;
                case "incomplete-signup":
                  return `<div class="badge badge-orange-soft-outline">
                                              Incomplete Signup
                                          </div>`;
                case "processing":
                  return `<div class="badge badge-orange-soft-outline">
                                              Processing
                                          </div>`;
                case "pending":
                default:
                  return `<div class="badge badge-orange-soft-outline">
                                              Pending
                                          </div>`;
              }
            },
          },
          {
            name: "status",
            th: "Payment Status",
            render: (conversions) => {
              const status = conversions.reward?.status ?? "not_converted";
              switch (status) {
                case "earned":
                  return `<div class="badge badge-green-soft-outline">
                                              Earned
                                          </div>`;
                case "processing":
                  return `<div class="badge badge-orange-soft-outline">
                                              Processing
                                          </div>`;
                case "disbursed":
                  return `<div class="badge badge-blue-soft-outline">
                                              Disbursed
                                          </div>`;
                case "rejected":
                  return `<div class="badge badge-red-soft-outline">
                                              Pending
                                          </div>`;
                case "not_converted":
                default:
                  return `<div class="badge badge-gray-soft-outline">
                                              Not Converted
                                          </div>`;
              }
            },
          },
        ],
        selected: null,
      }),
    };
  },
};
</script>
